import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
function Footer() {
    return (
        <footer>
            <Row>
                <Col className="text-center py-3 backdark">
                    Copyright &copy; Outfitter Finder
                </Col>
            </Row>
        </footer>


    )
}

export default Footer
