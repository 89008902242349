
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Image, ListGroup, Button, Card, DropdownButton, ButtonGroup, Dropdown, Form } from 'react-bootstrap'
import Rating from '../components/Rating'
import * as data from '../AppointmentData.json'
import * as apt from '../apt.json'
import upstate from "../media/upstate3.jpg"
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from "react-modern-calendar-datepicker";
import '../index.css'
function Outfitscreen() {
    const [qty, setQty] = useState(1)
    const [selectedDayRange, setSelectedDayRange] = useState({
        from: null,
        to: null
    });
    const [searchby, SelectSearchby] = useState("Nearest Availability")

    const numPeps = (e) => {
        if (e === 1) { return "Person" } else { return "People" }
    }
    const amtTime = (time, unit) => {
        if (unit === "hours" && time === 1) { return "Hour" } else if (unit === "hours") { return "Hours" }
        else if (unit === "days" && time === 1) { return "Day" } else if (unit === "days") { return "Days" }

    }

    console.log(apt.Appointments);

    return (
        <Container>
            <Link to="/search" className="btn btn-light my-3"> Go Back </Link>
            <Row>
                <Col md={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Image src={upstate} alt={data.Appointments[0].Name} fluid />
                </Col>

                <Col md={4}>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item >
                                <h4><strong>{data.Appointments[0].Name}</strong></h4>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Rating value={data.Appointments[0].Rating} text={`${data.Appointments[0].NumReviews} reviews`} color={'#f8e825'} />
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <h2>${data.Appointments[0].Price}<strong> <span style={{ margin: "auto", float: "right", fontSize: "1rem", color: "#f47c3c" }}>for {data.Appointments[0].NumPeople} {numPeps(data.Appointments[0].NumPeople)} over {data.Appointments[0].Duration} {amtTime(data.Appointments[0].Duration, data.Appointments[0].DurationMetric)}</span></strong> </h2>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                {data.Appointments[0].Description}
                            </ListGroup.Item>
                            <ListGroup.Item>

                                <h6><i className="fas fa-hiking"></i>: {data.Appointments[0].Sports.map((sport, index) => {
                                    if (index === 0) {
                                        return sport
                                    } return (<span>, {sport}</span>)
                                })}</h6>


                                {/* handle species if hunting or fishing only hunting, only fishing, then both couldve used state some how but didnt feel like it */}

                                {(data.Appointments[0].Sports.includes("Hunting") || data.Appointments[0].Sports.includes("Big Game Hunting") || data.Appointments[0].Sports.includes("Small Game Hunting")) &&
                                    (data.Appointments[0].Sports.includes("Fishing") === false && data.Appointments[0].Sports.includes("Fresh Water Fishing") === false && data.Appointments[0].Sports.includes("Salt Water Fishing") === false) &&
                                    (<div><i className="fas fa-dragon" /> : {data.Appointments[0].Species.map((species, index) => {
                                        if (index === 0) {
                                            return species
                                        } return (<span>, {species}</span>)
                                    })}</div>)}
                                {(data.Appointments[0].Sports.includes("Fishing") || data.Appointments[0].Sports.includes("Fresh Water Fishing") || data.Appointments[0].Sports.includes("Salt Water Fishing")) &&
                                    (data.Appointments[0].Sports.includes("Hunting") === false && data.Appointments[0].Sports.includes("Big Game Hunting") === false && data.Appointments[0].Sports.includes("Small Game Hunting") === false) &&
                                    (<div><i className="fas fa-fish" /> : {data.Appointments[0].Species.map((species, index) => {
                                        if (index === 0) {
                                            return species
                                        } return (<span>, {species}</span>)
                                    })}</div>)}
                                {(data.Appointments[0].Sports.includes("Hunting") || data.Appointments[0].Sports.includes("Big Game Hunting") || data.Appointments[0].Sports.includes("Small Game Hunting")) && (data.Appointments[0].Sports.includes("Fishing") || data.Appointments[0].Sports.includes("Fresh Water Fishing") || data.Appointments[0].Sports.includes("Salt Water Fishing")) &&
                                    (<div><i className="fas fa-dragon" /> /<i className="fas fa-fish" /> : {data.Appointments[0].Species.map((species, index) => {
                                        if (index === 0) {
                                            return species
                                        } return (<span>, {species}</span>)
                                    })}</div>)}


                            </ListGroup.Item>


                        </ListGroup>

                    </Card>


                </Col>

                <Col md={4}>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="list-group-item list-group-item-action active">
                                <Row>
                                    <Col> <h5><strong>Included:</strong></h5></Col>
                                    <Col> <h5><strong>Not Included: </strong></h5></Col>
                                </Row>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <Row>
                                    <Col><Card className="card border-warning" style={{ height: "100%" }}><ul> {data.Appointments[0].Included.map((key) => { return (<li>{key}</li>) })} </ul> </Card> </Col>
                                    <Col><Card className="card border-warning" style={{ height: "100%" }}> <ul >{data.Appointments[0].NotIncluded.map((key) => { return (<li>{key}</li>) })} </ul></Card></Col>
                                </Row>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <Row>

                                    <h6 style={{ textAlign: "center" }}><strong>Other Terms And Conditions:</strong></h6>
                                    {(data.Appointments[0].TermsandConditions === "") && <h6>No other Terms</h6>}
                                    {(data.Appointments[0].TermsandConditions !== "") && <ul>{data.Appointments[0].TermsandConditions.map((key) => { return (<li style={{ marginLeft: "8px" }}>{key}</li>) })} </ul>}

                                </Row>
                            </ListGroup.Item>


                            <ListGroup.Item className="text-center">
                                <Button

                                    className='btn-block btn-warning'
                                    style={{}}
                                    type='button'>
                                    Contact this Outfitter

                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>


                </Col>
            </Row >
            <Row>
                <Card className="card border-warning" style={{ marginTop: "2vw" }}>
                    <Row>
                        <Col md={5}>
                            <Card >
                                <ListGroup variant="" style={{ disply: "flex", alignItems: "center" }}>
                                    <ListGroup.Item className="list-group-item list-group-item-action active">
                                        <h6>Selected Dates</h6>


                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div>
                                            <Calendar
                                                value={selectedDayRange}
                                                onChange={setSelectedDayRange}
                                                calendarClassName="responsive-calendar" // added this
                                                shouldHighlightWeekends
                                                colorPrimary="#f47c3c"
                                                colorPrinaryLight="#D8885E"
                                            />
                                        </div>


                                    </ListGroup.Item>

                                </ListGroup>
                            </Card>

                        </Col>
                        <Col md={7}>
                            <Card>
                                <ListGroup variant="">
                                    <ListGroup.Item className="list-group-item list-group-item-action active">
                                        <Row><Col style={{ position: "relative" }}>
                                            <h6 style={{ position: "absolute", bottom: 0 }}><strong>Available Appointments:</strong></h6>
                                        </Col><Col>
                                                <DropdownButton
                                                    as={ButtonGroup}
                                                    key={'Warning'}
                                                    id={`dropdown-variants-warning`}
                                                    variant={'warning'.toLowerCase()}
                                                    title={searchby}
                                                    onSelect={(e) => SelectSearchby(e)}
                                                    style={{ float: "right", marginRight: "5px", marginTop: "10px" }}
                                                >

                                                    <Dropdown.Item eventKey="plh">Price <i className="fas fa-arrow-down" /> to <i className="fas fa-arrow-up" /> </Dropdown.Item>
                                                    <Dropdown.Item eventKey="phl">Price <i className="fas fa-arrow-up" /> to <i className="fas fa-arrow-down" /> </Dropdown.Item>
                                                    <Dropdown.Item eventKey="soon">Nearest Availability</Dropdown.Item>
                                                    <Dropdown.Item eventKey="soon">Highest rated</Dropdown.Item>

                                                </DropdownButton>
                                            </Col></Row>


                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            {

                                                apt.Appointments.map(
                                                    (appoitnment) => {
                                                        return (
                                                            <Col md={6}>
                                                                <Card>
                                                                    <ListGroup>
                                                                        <ListGroup.Item>
                                                                            <Row><Col > <h5 style={{ textAlign: "center", marginRight: "8px" }} ><strong>Start: {appoitnment.StartDate}</strong></h5> <h6 style={{ textAlign: "center", marginRight: "8px" }}>Duration:  {appoitnment.Duration} {amtTime(appoitnment.Duration, appoitnment.DurationMetric)}</h6></Col></Row>



                                                                        </ListGroup.Item>
                                                                        <ListGroup.Item>
                                                                            <h6><strong>${appoitnment.Price}</strong><span style={{ margin: "auto", float: "right", fontSize: "1rem", color: "#f47c3c" }}>Guests: {appoitnment.NumPeople} </span> </h6>



                                                                        </ListGroup.Item>
                                                                        {appoitnment.NumAvaialable > 0 && (
                                                                            <ListGroup.Item>
                                                                                <Row>
                                                                                    <Col> Qty </Col>
                                                                                    <Col xs='auto' className='my-1'>
                                                                                        <Form.Control
                                                                                            as="select"
                                                                                            value={qty}
                                                                                            onChange={(e) => setQty(e.target.value)}
                                                                                        >
                                                                                            {
                                                                                                [...Array(appoitnment.NumAvaialable).keys()].map((x) => (
                                                                                                    <option key={x + 1} value={x + 1}> {x + 1} </option>


                                                                                                ))
                                                                                            }
                                                                                        </Form.Control>
                                                                                        {appoitnment.NumAvaialable > 0 ? 'In stock' : 'Out of Stock'}</Col>
                                                                                </Row>
                                                                            </ListGroup.Item>

                                                                        )}

                                                                        <ListGroup.Item className="text-center">
                                                                            <Button

                                                                                className='btn-block btn-warning'
                                                                                style={{}}
                                                                                type='button'>
                                                                                Book now

                                                                            </Button>
                                                                        </ListGroup.Item>


                                                                    </ListGroup>

                                                                </Card>
                                                            </Col>
                                                        )
                                                    }


                                                )
                                            }
                                        </Row>







                                    </ListGroup.Item>

                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </Row>


        </Container >


    )
}

export default Outfitscreen
