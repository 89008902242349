import React from 'react'
import '../index.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import logo from '../media/OutfitterFinderLogo-green-small-2.png'

import { Navbar, Nav, Container, Row, Col, NavDropdown, Image } from 'react-bootstrap'
function Header() {
    return (

        <header>
            <Navbar bg="light" variant="light" expand="lg">
                <Container>
                    <Navbar.Brand href="/"><Image src={logo} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#home" className="btn btn btn-outline-secondary p-3 m-2 ">Hunting</Nav.Link>
                            <Nav.Link href="#link" className="btn btn btn-outline-secondary p-3 m-2 ">Fishing</Nav.Link>
                            <NavDropdown title="Outdoor Sports" className="btn btn-outline-secondary m-2" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Air Sports</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Land Sports</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Water Sports</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Winter Sports</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav className="pull-right btn-group">
                            <Nav.Link href="#link" className="btn btn-warning p-3">Become An OutFitter</Nav.Link>
                            <NavDropdown title={<i className="fas fa-user"> </i>} className="btn btn-warning" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3"> <AmplifySignOut /></NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>





        </header>


    )
}

export default Header
