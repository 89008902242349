import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import upstate from '../media/upstate3.jpg'
import joe from '../media/joe11.jpg'
import HomeCard from '../components/HomeCard'
import CssMap from '../components/CssMap'
import { useMediaQuery } from 'react-responsive'
import { Image, Row } from 'react-bootstrap'
function HomeScreen({ }) {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1225px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    return (
        <div>
            {isBigScreen &&
                <Carousel
                    autoPlay={false}
                    interval={null}
                    controls={true}
                    indicators={false}
                >

                    <Carousel.Item >
                        <img
                            className="d-block w-100"
                            src={joe}
                            alt="First slide"
                        />
                        <Carousel.Caption style={{
                            top: "45%",
                            transform: "translateY(-50%)"
                        }}>
                            <HomeCard />

                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item >
                        <img
                            className="d-block w-100"
                            src={upstate}
                            alt="Second slide"
                        />


                        <Carousel.Caption>
                            <CssMap />
                        </Carousel.Caption>
                    </Carousel.Item>

                </Carousel>
            }
            {isTabletOrMobile && <div style={{ margin: 'auto' }}>
                <div style={{ margin: "auto" }}>
                    <HomeCard />
                </div>


            </div>}







        </div >
    )
}

export default HomeScreen
