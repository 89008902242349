import React from 'react';
import './index.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen copy'
import OutfitScreen from './screens/OutfitScreen'
import SearchScreen from './screens/Searchscreen'

import upstate from './upstate2.jpg'
function App() {
  return (
    <Router className="App">
      <Header />
      <main className="">
        <Route path="/" component={HomeScreen} exact />
        <Route path="/search/" component={SearchScreen} />
        <Route path="/outfit/" component={OutfitScreen} />






      </main>
      <Footer />

    </Router>
  );
}

export default withAuthenticator(App);
