import React, { useState, useEffect } from 'react'
import ReactMapGL, { Marker, Popup } from "react-map-gl"
import * as data from '../skateboard-parks.json'
import mapMarker from '../media/h.png'
import { Image } from 'react-bootstrap'
function MapBox() {
    const [viewport, setViewPort] = useState({
        latitude: 45.421,
        longitude: -75.6903,
        width: "100vw",
        height: "35vh",
        zoom: 10
    })
    const [selectedOutfit, setSelectedOutfit] = useState(null);
    require('dotenv').config();

    useEffect(() => {
        const listener = (e) => {
            if (e.key === "Escape") {
                setSelectedOutfit(null)
            }
        };
        window.addEventListener("keydown", listener);

        return () => {
            window.removeEventListener("keydown", listener);
        }


    }, [])
    return (
        <div>
            <ReactMapGL style={{ margin: 'auto' }}
                {...viewport}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                mapStyle="mapbox://styles/josephleva/cksnefz6959ju17o322s6vgzp"
                onViewportChange={viewport => {
                    setViewPort(viewport);
                }}>
                {data.features.map((park) => (

                    <Marker
                        key={park.properties.PARK_ID}
                        latitude={park.geometry.coordinates[1]}
                        longitude={park.geometry.coordinates[0]} >


                        <button
                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    setSelectedOutfit(park)

                                }
                            }
                        > <Image src={mapMarker} /> </button>


                    </Marker>
                ))}

                {selectedOutfit && (
                    <Popup latitude={selectedOutfit.geometry.coordinates[1]}
                        longitude={selectedOutfit.geometry.coordinates[0]}
                        onClose={() => {
                            setSelectedOutfit(null);
                        }}>
                        <div>
                            <h2>{selectedOutfit.properties.NAME}</h2>
                        </div>
                    </Popup>

                )}


            </ReactMapGL>


        </div>
    )
}

export default MapBox
