import React, { useState, useEffect } from 'react'
import MapBox from '../components/MapBox'
import FilterSidebar from '../components/FilterSidebar'
import { Card, Row, Col, Image, Dropdown, DropdownButton, ButtonGroup, ListGroup, Button } from 'react-bootstrap'
import * as data from '../AppointmentData.json'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { listAppointments } from '../graphql/queries'
import Rating from '../components/Rating'
import upstate from "../media/upstate3.jpg"
function Searchscreen(history) {
    const [searchby, SelectSearchby] = useState("Revalence")
    const [marg, setMarg] = useState('')
    const [apps, setApps] = useState('')


    // const [pad, setpad] = useState("10vw")
    // const collapse = () => {
    //     if (FilterSidebar.collapse == true) {
    //         return 0
    //     } else { setpad('25vw') }

    //     console.log(FilterSidebar.collapse())
    // }
    const amtTime = (time, unit) => {
        if (unit === "hours" && time === 1) { return "Hour" } else if (unit === "hours") { return "Hours" }
        else if (unit === "days" && time === 1) { return "Day" } else if (unit === "days") { return "Days" }

    }
    async function fetchAppointments() {
        try {
            const appData = await API.graphql(graphqlOperation(listAppointments))
            const appon = appData.data.listAppointments.items
            console.log(appData, "HI")
            setApps(appon)
        } catch (err) { console.log(err) }
    }



    const setMargCoordinator = (e) => {
        setMarg(String(e))

    }
    // console.log(history.location.state)


    return (
        <div>
            <MapBox />


            <Row>
                <Col style={{ maxWidth: "45px" }}>
                    <FilterSidebar setMargCoordinator={setMargCoordinator} />
                </Col>
                <Col className="" style={{ marginLeft: marg }}>

                    <Row><Col><h2 style={{ marginTop: "5px" }}><strong>Results:</strong></h2></Col><Col>  <DropdownButton
                        as={ButtonGroup}
                        key={'Warning'}
                        id={`dropdown-variants-warning`}
                        variant={'warning'.toLowerCase()}
                        title={searchby}
                        onSelect={(e) => {
                            SelectSearchby(e)
                            fetchAppointments()
                        }}
                        style={{ float: "right", marginRight: "5px", marginTop: "10px" }}
                    >

                        <Dropdown.Item eventKey="plh">Price <i className="fas fa-arrow-down" /> to <i className="fas fa-arrow-up" /> </Dropdown.Item>
                        <Dropdown.Item eventKey="phl">Price <i className="fas fa-arrow-up" /> to <i className="fas fa-arrow-down" /> </Dropdown.Item>
                        <Dropdown.Item eventKey="soon">Soonest Availability</Dropdown.Item>
                        <Dropdown.Item eventKey="soon">Highest rated</Dropdown.Item>

                    </DropdownButton></Col></Row>




                    <hr />
                    <Row>


                        {data.Appointments.map((appointment) => {
                            return (
                                <Row>
                                    <Card>
                                        <Row className="card-header">
                                            <Col md={8}>
                                                <h5><strong> {appointment.Name}/{appointment.Location}</strong></h5>
                                            </Col>


                                        </Row>
                                        <Row style={{ margin: "10px 0 10px 0" }}>
                                            <Col md={3}>

                                                <Image src={upstate} style={{ maxWidth: "20vw", maxHeight: "20vh", margin: "auto", padding: "0px 20px 15px 20px" }} />


                                            </Col>
                                            <Col md={6}>
                                                <p className="shorten">{appointment.Description}</p>
                                                <h6><i className="fas fa-hiking"></i>: {appointment.Sports.map((sport, index) => {
                                                    if (index === 0) {
                                                        return sport
                                                    } return (<span>, {sport}</span>)
                                                })}</h6>


                                                {/* handle species if hunting or fishing only hunting, only fishing, then both couldve used state some how but didnt feel like it */}

                                                {(appointment.Sports.includes("Hunting") || appointment.Sports.includes("Big Game Hunting") || appointment.Sports.includes("Small Game Hunting")) &&
                                                    (appointment.Sports.includes("Fishing") === false && appointment.Sports.includes("Fresh Water Fishing") === false && appointment.Sports.includes("Salt Water Fishing") === false) &&
                                                    (<div><i className="fas fa-dragon" /> : {appointment.Species.map((species, index) => {
                                                        if (index === 0) {
                                                            return species
                                                        } return (<span>, {species}</span>)
                                                    })}</div>)}
                                                {(appointment.Sports.includes("Fishing") || appointment.Sports.includes("Fresh Water Fishing") || appointment.Sports.includes("Salt Water Fishing")) &&
                                                    (appointment.Sports.includes("Hunting") === false && appointment.Sports.includes("Big Game Hunting") === false && appointment.Sports.includes("Small Game Hunting") === false) &&
                                                    (<div><i className="fas fa-fish" /> : {appointment.Species.map((species, index) => {
                                                        if (index === 0) {
                                                            return species
                                                        } return (<span>, {species}</span>)
                                                    })}  </div>)}
                                                {(appointment.Sports.includes("Hunting") || appointment.Sports.includes("Big Game Hunting") || appointment.Sports.includes("Small Game Hunting")) && (appointment.Sports.includes("Fishing") || appointment.Sports.includes("Fresh Water Fishing") || appointment.Sports.includes("Salt Water Fishing")) &&
                                                    (<div><i className="fas fa-dragon" /> /<i className="fas fa-fish" /> : {appointment.Species.map((species, index) => {
                                                        if (index === 0) {
                                                            return species
                                                        } return (<span>, {species}</span>)
                                                    })}  <Rating value={data.Appointments[0].Rating} text={`${appointment.NumReviews} reviews`} color={'#f8e825'} className="" />
                                                    </div>)}
                                                <span style={{ textAlign: "right" }}><Rating value={data.Appointments[0].Rating} text={`${appointment.NumReviews} reviews`} color={'#f8e825'} className="" /></span>






                                            </Col>
                                            <Col md={3} style={{}}>
                                                <Card className="card border-warning">
                                                    <ListGroup>
                                                        <ListGroup.Item>
                                                            <h4><strong>Details:</strong></h4>




                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            <div ><h6 style={{ margin: "auto", textAlign: "center", }}><strong>Starting at: ${appointment.Price}</strong><span style={{ color: "#f47c3c" }}></span></h6></div>
                                                            <div> <h6 style={{ margin: "auto", textAlign: "center", marginTop: "2vh" }}> <strong>Up to  {appointment.NumPeople} Guests for {appointment.Duration} {amtTime(appointment.Duration, appointment.DurationMetric)}<div style={{ textAlign: "center", marginTop: "2vh", color: "red" }}>Slots Available: {appointment.NumAvailable}</div></strong></h6></div>


                                                        </ListGroup.Item>
                                                        <ListGroup.Item className="text-center">
                                                            <Button

                                                                className='btn-block btn-warning'
                                                                style={{}}
                                                                type='button'>
                                                                View Booking

                                                            </Button>


                                                        </ListGroup.Item>



                                                    </ListGroup>

                                                </Card>



                                            </Col>
                                        </Row>


                                    </Card>


                                </Row>


                            )
                        })}

                    </Row>
                </Col>

            </Row>






        </div >
    )
}

export default Searchscreen
