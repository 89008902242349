/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOutfit = /* GraphQL */ `
  query GetOutfit($id: ID!) {
    getOutfit(id: $id) {
      id
      name
      description
      bookings {
        items {
          id
          outfitID
          outfit {
            id
            name
            description
            bookings {
              items {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                Description
                Name
                Loaction
                LocationGeo
                Included
                NotIncluded
                Terms
                appoitnments {
                  items {
                    id
                    bookingId
                    booking {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    StartDate
                    Duration
                    DurationMetric
                    NumPeople
                    AllSame
                    Price
                    NumAvailable
                    Sports
                    Species
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              items {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                description
                rating
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          Description
          Name
          Loaction
          LocationGeo
          Included
          NotIncluded
          Terms
          appoitnments {
            items {
              id
              bookingId
              booking {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                Description
                Name
                Loaction
                LocationGeo
                Included
                NotIncluded
                Terms
                appoitnments {
                  items {
                    id
                    bookingId
                    booking {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    StartDate
                    Duration
                    DurationMetric
                    NumPeople
                    AllSame
                    Price
                    NumAvailable
                    Sports
                    Species
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              StartDate
              Duration
              DurationMetric
              NumPeople
              AllSame
              Price
              NumAvailable
              Sports
              Species
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          outfitID
          outfit {
            id
            name
            description
            bookings {
              items {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                Description
                Name
                Loaction
                LocationGeo
                Included
                NotIncluded
                Terms
                appoitnments {
                  items {
                    id
                    bookingId
                    booking {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    StartDate
                    Duration
                    DurationMetric
                    NumPeople
                    AllSame
                    Price
                    NumAvailable
                    Sports
                    Species
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              items {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                description
                rating
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          description
          rating
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOutfits = /* GraphQL */ `
  query ListOutfits(
    $filter: ModelOutfitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOutfits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        bookings {
          items {
            id
            outfitID
            outfit {
              id
              name
              description
              bookings {
                items {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  Description
                  Name
                  Loaction
                  LocationGeo
                  Included
                  NotIncluded
                  Terms
                  appoitnments {
                    items {
                      id
                      bookingId
                      booking {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      StartDate
                      Duration
                      DurationMetric
                      NumPeople
                      AllSame
                      Price
                      NumAvailable
                      Sports
                      Species
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                items {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  description
                  rating
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              owner
            }
            Description
            Name
            Loaction
            LocationGeo
            Included
            NotIncluded
            Terms
            appoitnments {
              items {
                id
                bookingId
                booking {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  Description
                  Name
                  Loaction
                  LocationGeo
                  Included
                  NotIncluded
                  Terms
                  appoitnments {
                    items {
                      id
                      bookingId
                      booking {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      StartDate
                      Duration
                      DurationMetric
                      NumPeople
                      AllSame
                      Price
                      NumAvailable
                      Sports
                      Species
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                StartDate
                Duration
                DurationMetric
                NumPeople
                AllSame
                Price
                NumAvailable
                Sports
                Species
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            outfitID
            outfit {
              id
              name
              description
              bookings {
                items {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  Description
                  Name
                  Loaction
                  LocationGeo
                  Included
                  NotIncluded
                  Terms
                  appoitnments {
                    items {
                      id
                      bookingId
                      booking {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      StartDate
                      Duration
                      DurationMetric
                      NumPeople
                      AllSame
                      Price
                      NumAvailable
                      Sports
                      Species
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                items {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  description
                  rating
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              owner
            }
            description
            rating
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      outfitID
      outfit {
        id
        name
        description
        bookings {
          items {
            id
            outfitID
            outfit {
              id
              name
              description
              bookings {
                items {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  Description
                  Name
                  Loaction
                  LocationGeo
                  Included
                  NotIncluded
                  Terms
                  appoitnments {
                    items {
                      id
                      bookingId
                      booking {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      StartDate
                      Duration
                      DurationMetric
                      NumPeople
                      AllSame
                      Price
                      NumAvailable
                      Sports
                      Species
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                items {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  description
                  rating
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              owner
            }
            Description
            Name
            Loaction
            LocationGeo
            Included
            NotIncluded
            Terms
            appoitnments {
              items {
                id
                bookingId
                booking {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  Description
                  Name
                  Loaction
                  LocationGeo
                  Included
                  NotIncluded
                  Terms
                  appoitnments {
                    items {
                      id
                      bookingId
                      booking {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      StartDate
                      Duration
                      DurationMetric
                      NumPeople
                      AllSame
                      Price
                      NumAvailable
                      Sports
                      Species
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                StartDate
                Duration
                DurationMetric
                NumPeople
                AllSame
                Price
                NumAvailable
                Sports
                Species
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            outfitID
            outfit {
              id
              name
              description
              bookings {
                items {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  Description
                  Name
                  Loaction
                  LocationGeo
                  Included
                  NotIncluded
                  Terms
                  appoitnments {
                    items {
                      id
                      bookingId
                      booking {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      StartDate
                      Duration
                      DurationMetric
                      NumPeople
                      AllSame
                      Price
                      NumAvailable
                      Sports
                      Species
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                items {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  description
                  rating
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              owner
            }
            description
            rating
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      Description
      Name
      Loaction
      LocationGeo
      Included
      NotIncluded
      Terms
      appoitnments {
        items {
          id
          bookingId
          booking {
            id
            outfitID
            outfit {
              id
              name
              description
              bookings {
                items {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  Description
                  Name
                  Loaction
                  LocationGeo
                  Included
                  NotIncluded
                  Terms
                  appoitnments {
                    items {
                      id
                      bookingId
                      booking {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      StartDate
                      Duration
                      DurationMetric
                      NumPeople
                      AllSame
                      Price
                      NumAvailable
                      Sports
                      Species
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                items {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  description
                  rating
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              owner
            }
            Description
            Name
            Loaction
            LocationGeo
            Included
            NotIncluded
            Terms
            appoitnments {
              items {
                id
                bookingId
                booking {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  Description
                  Name
                  Loaction
                  LocationGeo
                  Included
                  NotIncluded
                  Terms
                  appoitnments {
                    items {
                      id
                      bookingId
                      booking {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      StartDate
                      Duration
                      DurationMetric
                      NumPeople
                      AllSame
                      Price
                      NumAvailable
                      Sports
                      Species
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                StartDate
                Duration
                DurationMetric
                NumPeople
                AllSame
                Price
                NumAvailable
                Sports
                Species
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          StartDate
          Duration
          DurationMetric
          NumPeople
          AllSame
          Price
          NumAvailable
          Sports
          Species
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        outfitID
        outfit {
          id
          name
          description
          bookings {
            items {
              id
              outfitID
              outfit {
                id
                name
                description
                bookings {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    Description
                    Name
                    Loaction
                    LocationGeo
                    Included
                    NotIncluded
                    Terms
                    appoitnments {
                      items {
                        id
                        bookingId
                        StartDate
                        Duration
                        DurationMetric
                        NumPeople
                        AllSame
                        Price
                        NumAvailable
                        Sports
                        Species
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    description
                    rating
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
                owner
              }
              Description
              Name
              Loaction
              LocationGeo
              Included
              NotIncluded
              Terms
              appoitnments {
                items {
                  id
                  bookingId
                  booking {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    Description
                    Name
                    Loaction
                    LocationGeo
                    Included
                    NotIncluded
                    Terms
                    appoitnments {
                      items {
                        id
                        bookingId
                        StartDate
                        Duration
                        DurationMetric
                        NumPeople
                        AllSame
                        Price
                        NumAvailable
                        Sports
                        Species
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  StartDate
                  Duration
                  DurationMetric
                  NumPeople
                  AllSame
                  Price
                  NumAvailable
                  Sports
                  Species
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              outfitID
              outfit {
                id
                name
                description
                bookings {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    Description
                    Name
                    Loaction
                    LocationGeo
                    Included
                    NotIncluded
                    Terms
                    appoitnments {
                      items {
                        id
                        bookingId
                        StartDate
                        Duration
                        DurationMetric
                        NumPeople
                        AllSame
                        Price
                        NumAvailable
                        Sports
                        Species
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    description
                    rating
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
                owner
              }
              description
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        Description
        Name
        Loaction
        LocationGeo
        Included
        NotIncluded
        Terms
        appoitnments {
          items {
            id
            bookingId
            booking {
              id
              outfitID
              outfit {
                id
                name
                description
                bookings {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    Description
                    Name
                    Loaction
                    LocationGeo
                    Included
                    NotIncluded
                    Terms
                    appoitnments {
                      items {
                        id
                        bookingId
                        StartDate
                        Duration
                        DurationMetric
                        NumPeople
                        AllSame
                        Price
                        NumAvailable
                        Sports
                        Species
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    description
                    rating
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
                owner
              }
              Description
              Name
              Loaction
              LocationGeo
              Included
              NotIncluded
              Terms
              appoitnments {
                items {
                  id
                  bookingId
                  booking {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    Description
                    Name
                    Loaction
                    LocationGeo
                    Included
                    NotIncluded
                    Terms
                    appoitnments {
                      items {
                        id
                        bookingId
                        StartDate
                        Duration
                        DurationMetric
                        NumPeople
                        AllSame
                        Price
                        NumAvailable
                        Sports
                        Species
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  StartDate
                  Duration
                  DurationMetric
                  NumPeople
                  AllSame
                  Price
                  NumAvailable
                  Sports
                  Species
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            StartDate
            Duration
            DurationMetric
            NumPeople
            AllSame
            Price
            NumAvailable
            Sports
            Species
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppointments = /* GraphQL */ `
  query GetAppointments($id: ID!) {
    getAppointments(id: $id) {
      id
      bookingId
      booking {
        id
        outfitID
        outfit {
          id
          name
          description
          bookings {
            items {
              id
              outfitID
              outfit {
                id
                name
                description
                bookings {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    Description
                    Name
                    Loaction
                    LocationGeo
                    Included
                    NotIncluded
                    Terms
                    appoitnments {
                      items {
                        id
                        bookingId
                        StartDate
                        Duration
                        DurationMetric
                        NumPeople
                        AllSame
                        Price
                        NumAvailable
                        Sports
                        Species
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    description
                    rating
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
                owner
              }
              Description
              Name
              Loaction
              LocationGeo
              Included
              NotIncluded
              Terms
              appoitnments {
                items {
                  id
                  bookingId
                  booking {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    Description
                    Name
                    Loaction
                    LocationGeo
                    Included
                    NotIncluded
                    Terms
                    appoitnments {
                      items {
                        id
                        bookingId
                        StartDate
                        Duration
                        DurationMetric
                        NumPeople
                        AllSame
                        Price
                        NumAvailable
                        Sports
                        Species
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  StartDate
                  Duration
                  DurationMetric
                  NumPeople
                  AllSame
                  Price
                  NumAvailable
                  Sports
                  Species
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              outfitID
              outfit {
                id
                name
                description
                bookings {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    Description
                    Name
                    Loaction
                    LocationGeo
                    Included
                    NotIncluded
                    Terms
                    appoitnments {
                      items {
                        id
                        bookingId
                        StartDate
                        Duration
                        DurationMetric
                        NumPeople
                        AllSame
                        Price
                        NumAvailable
                        Sports
                        Species
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    description
                    rating
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
                owner
              }
              description
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        Description
        Name
        Loaction
        LocationGeo
        Included
        NotIncluded
        Terms
        appoitnments {
          items {
            id
            bookingId
            booking {
              id
              outfitID
              outfit {
                id
                name
                description
                bookings {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    Description
                    Name
                    Loaction
                    LocationGeo
                    Included
                    NotIncluded
                    Terms
                    appoitnments {
                      items {
                        id
                        bookingId
                        StartDate
                        Duration
                        DurationMetric
                        NumPeople
                        AllSame
                        Price
                        NumAvailable
                        Sports
                        Species
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    description
                    rating
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
                owner
              }
              Description
              Name
              Loaction
              LocationGeo
              Included
              NotIncluded
              Terms
              appoitnments {
                items {
                  id
                  bookingId
                  booking {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    Description
                    Name
                    Loaction
                    LocationGeo
                    Included
                    NotIncluded
                    Terms
                    appoitnments {
                      items {
                        id
                        bookingId
                        StartDate
                        Duration
                        DurationMetric
                        NumPeople
                        AllSame
                        Price
                        NumAvailable
                        Sports
                        Species
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  StartDate
                  Duration
                  DurationMetric
                  NumPeople
                  AllSame
                  Price
                  NumAvailable
                  Sports
                  Species
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            StartDate
            Duration
            DurationMetric
            NumPeople
            AllSame
            Price
            NumAvailable
            Sports
            Species
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      StartDate
      Duration
      DurationMetric
      NumPeople
      AllSame
      Price
      NumAvailable
      Sports
      Species
      createdAt
      updatedAt
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $filter: ModelAppointmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bookingId
        booking {
          id
          outfitID
          outfit {
            id
            name
            description
            bookings {
              items {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                Description
                Name
                Loaction
                LocationGeo
                Included
                NotIncluded
                Terms
                appoitnments {
                  items {
                    id
                    bookingId
                    booking {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    StartDate
                    Duration
                    DurationMetric
                    NumPeople
                    AllSame
                    Price
                    NumAvailable
                    Sports
                    Species
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              items {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                description
                rating
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          Description
          Name
          Loaction
          LocationGeo
          Included
          NotIncluded
          Terms
          appoitnments {
            items {
              id
              bookingId
              booking {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                Description
                Name
                Loaction
                LocationGeo
                Included
                NotIncluded
                Terms
                appoitnments {
                  items {
                    id
                    bookingId
                    booking {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    StartDate
                    Duration
                    DurationMetric
                    NumPeople
                    AllSame
                    Price
                    NumAvailable
                    Sports
                    Species
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              StartDate
              Duration
              DurationMetric
              NumPeople
              AllSame
              Price
              NumAvailable
              Sports
              Species
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        StartDate
        Duration
        DurationMetric
        NumPeople
        AllSame
        Price
        NumAvailable
        Sports
        Species
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      outfitID
      outfit {
        id
        name
        description
        bookings {
          items {
            id
            outfitID
            outfit {
              id
              name
              description
              bookings {
                items {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  Description
                  Name
                  Loaction
                  LocationGeo
                  Included
                  NotIncluded
                  Terms
                  appoitnments {
                    items {
                      id
                      bookingId
                      booking {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      StartDate
                      Duration
                      DurationMetric
                      NumPeople
                      AllSame
                      Price
                      NumAvailable
                      Sports
                      Species
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                items {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  description
                  rating
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              owner
            }
            Description
            Name
            Loaction
            LocationGeo
            Included
            NotIncluded
            Terms
            appoitnments {
              items {
                id
                bookingId
                booking {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  Description
                  Name
                  Loaction
                  LocationGeo
                  Included
                  NotIncluded
                  Terms
                  appoitnments {
                    items {
                      id
                      bookingId
                      booking {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      StartDate
                      Duration
                      DurationMetric
                      NumPeople
                      AllSame
                      Price
                      NumAvailable
                      Sports
                      Species
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                StartDate
                Duration
                DurationMetric
                NumPeople
                AllSame
                Price
                NumAvailable
                Sports
                Species
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        reviews {
          items {
            id
            outfitID
            outfit {
              id
              name
              description
              bookings {
                items {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  Description
                  Name
                  Loaction
                  LocationGeo
                  Included
                  NotIncluded
                  Terms
                  appoitnments {
                    items {
                      id
                      bookingId
                      booking {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      StartDate
                      Duration
                      DurationMetric
                      NumPeople
                      AllSame
                      Price
                      NumAvailable
                      Sports
                      Species
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              reviews {
                items {
                  id
                  outfitID
                  outfit {
                    id
                    name
                    description
                    bookings {
                      items {
                        id
                        outfitID
                        Description
                        Name
                        Loaction
                        LocationGeo
                        Included
                        NotIncluded
                        Terms
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    reviews {
                      items {
                        id
                        outfitID
                        description
                        rating
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  description
                  rating
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              owner
            }
            description
            rating
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      description
      rating
      createdAt
      updatedAt
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        outfitID
        outfit {
          id
          name
          description
          bookings {
            items {
              id
              outfitID
              outfit {
                id
                name
                description
                bookings {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    Description
                    Name
                    Loaction
                    LocationGeo
                    Included
                    NotIncluded
                    Terms
                    appoitnments {
                      items {
                        id
                        bookingId
                        StartDate
                        Duration
                        DurationMetric
                        NumPeople
                        AllSame
                        Price
                        NumAvailable
                        Sports
                        Species
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    description
                    rating
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
                owner
              }
              Description
              Name
              Loaction
              LocationGeo
              Included
              NotIncluded
              Terms
              appoitnments {
                items {
                  id
                  bookingId
                  booking {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    Description
                    Name
                    Loaction
                    LocationGeo
                    Included
                    NotIncluded
                    Terms
                    appoitnments {
                      items {
                        id
                        bookingId
                        StartDate
                        Duration
                        DurationMetric
                        NumPeople
                        AllSame
                        Price
                        NumAvailable
                        Sports
                        Species
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  StartDate
                  Duration
                  DurationMetric
                  NumPeople
                  AllSame
                  Price
                  NumAvailable
                  Sports
                  Species
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          reviews {
            items {
              id
              outfitID
              outfit {
                id
                name
                description
                bookings {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    Description
                    Name
                    Loaction
                    LocationGeo
                    Included
                    NotIncluded
                    Terms
                    appoitnments {
                      items {
                        id
                        bookingId
                        StartDate
                        Duration
                        DurationMetric
                        NumPeople
                        AllSame
                        Price
                        NumAvailable
                        Sports
                        Species
                        createdAt
                        updatedAt
                      }
                      nextToken
                    }
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                reviews {
                  items {
                    id
                    outfitID
                    outfit {
                      id
                      name
                      description
                      bookings {
                        nextToken
                      }
                      reviews {
                        nextToken
                      }
                      createdAt
                      updatedAt
                      owner
                    }
                    description
                    rating
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
                owner
              }
              description
              rating
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        description
        rating
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSports = /* GraphQL */ `
  query GetSports($id: ID!) {
    getSports(id: $id) {
      id
      Value
      createdAt
      updatedAt
    }
  }
`;
export const listSports = /* GraphQL */ `
  query ListSports(
    $filter: ModelSportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpecies = /* GraphQL */ `
  query GetSpecies($id: ID!) {
    getSpecies(id: $id) {
      id
      Value
      createdAt
      updatedAt
    }
  }
`;
export const listSpecies = /* GraphQL */ `
  query ListSpecies(
    $filter: ModelSpeciesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLocations = /* GraphQL */ `
  query GetLocations($id: ID!) {
    getLocations(id: $id) {
      id
      Value
      createdAt
      updatedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModellocationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const appointmentsAll = /* GraphQL */ `
  query AppointmentsAll(
    $StartDate: AWSDateTime
    $sportsSpecies: ModelAppointmentsByallCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentsAll(
      StartDate: $StartDate
      sportsSpecies: $sportsSpecies
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        booking {
          id
          outfitID
          outfit {
            id
            name
            description
            bookings {
              items {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                Description
                Name
                Loaction
                LocationGeo
                Included
                NotIncluded
                Terms
                appoitnments {
                  items {
                    id
                    bookingId
                    booking {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    StartDate
                    Duration
                    DurationMetric
                    NumPeople
                    AllSame
                    Price
                    NumAvailable
                    Sports
                    Species
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              items {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                description
                rating
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          Description
          Name
          Loaction
          LocationGeo
          Included
          NotIncluded
          Terms
          appoitnments {
            items {
              id
              bookingId
              booking {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                Description
                Name
                Loaction
                LocationGeo
                Included
                NotIncluded
                Terms
                appoitnments {
                  items {
                    id
                    bookingId
                    booking {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    StartDate
                    Duration
                    DurationMetric
                    NumPeople
                    AllSame
                    Price
                    NumAvailable
                    Sports
                    Species
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              StartDate
              Duration
              DurationMetric
              NumPeople
              AllSame
              Price
              NumAvailable
              Sports
              Species
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        StartDate
        Duration
        DurationMetric
        NumPeople
        AllSame
        Price
        NumAvailable
        Sports
        Species
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const appointmentDate = /* GraphQL */ `
  query AppointmentDate(
    $StartDate: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentDate(
      StartDate: $StartDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        booking {
          id
          outfitID
          outfit {
            id
            name
            description
            bookings {
              items {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                Description
                Name
                Loaction
                LocationGeo
                Included
                NotIncluded
                Terms
                appoitnments {
                  items {
                    id
                    bookingId
                    booking {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    StartDate
                    Duration
                    DurationMetric
                    NumPeople
                    AllSame
                    Price
                    NumAvailable
                    Sports
                    Species
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              items {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                description
                rating
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          Description
          Name
          Loaction
          LocationGeo
          Included
          NotIncluded
          Terms
          appoitnments {
            items {
              id
              bookingId
              booking {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                Description
                Name
                Loaction
                LocationGeo
                Included
                NotIncluded
                Terms
                appoitnments {
                  items {
                    id
                    bookingId
                    booking {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    StartDate
                    Duration
                    DurationMetric
                    NumPeople
                    AllSame
                    Price
                    NumAvailable
                    Sports
                    Species
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              StartDate
              Duration
              DurationMetric
              NumPeople
              AllSame
              Price
              NumAvailable
              Sports
              Species
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        StartDate
        Duration
        DurationMetric
        NumPeople
        AllSame
        Price
        NumAvailable
        Sports
        Species
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const appointmentsSport = /* GraphQL */ `
  query AppointmentsSport(
    $Sports: String
    $Species: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentsSport(
      Sports: $Sports
      Species: $Species
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        booking {
          id
          outfitID
          outfit {
            id
            name
            description
            bookings {
              items {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                Description
                Name
                Loaction
                LocationGeo
                Included
                NotIncluded
                Terms
                appoitnments {
                  items {
                    id
                    bookingId
                    booking {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    StartDate
                    Duration
                    DurationMetric
                    NumPeople
                    AllSame
                    Price
                    NumAvailable
                    Sports
                    Species
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            reviews {
              items {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                description
                rating
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          Description
          Name
          Loaction
          LocationGeo
          Included
          NotIncluded
          Terms
          appoitnments {
            items {
              id
              bookingId
              booking {
                id
                outfitID
                outfit {
                  id
                  name
                  description
                  bookings {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  reviews {
                    items {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      description
                      rating
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                  createdAt
                  updatedAt
                  owner
                }
                Description
                Name
                Loaction
                LocationGeo
                Included
                NotIncluded
                Terms
                appoitnments {
                  items {
                    id
                    bookingId
                    booking {
                      id
                      outfitID
                      outfit {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        owner
                      }
                      Description
                      Name
                      Loaction
                      LocationGeo
                      Included
                      NotIncluded
                      Terms
                      appoitnments {
                        nextToken
                      }
                      createdAt
                      updatedAt
                    }
                    StartDate
                    Duration
                    DurationMetric
                    NumPeople
                    AllSame
                    Price
                    NumAvailable
                    Sports
                    Species
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              StartDate
              Duration
              DurationMetric
              NumPeople
              AllSame
              Price
              NumAvailable
              Sports
              Species
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        StartDate
        Duration
        DurationMetric
        NumPeople
        AllSame
        Price
        NumAvailable
        Sports
        Species
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
