import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Row, Col, Card, Dropdown, DropdownButton, ButtonGroup, ListGroup } from 'react-bootstrap'
import '../index.css';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from "react-modern-calendar-datepicker";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useHistory } from "react-router-dom";


function useOutsideAlerter(ref) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {

                document.documentElement.style.setProperty('--cal-size', '0px')

            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


function HomeCard() {
    const today = new Date()
    const tomorrow = new Date()
    var [startDate, setStartDate] = useState(today)
    var [endDate, setEndDate] = useState(tomorrow.setDate(today.getDate() + 1))
    var [filterType, setFilterType] = useState("sports");
    var [filterInput, setfilterInput] = useState({ sports: [], species: [], locations: [] });
    var [searchTerm, setSearch] = useState('');
    const [selectedDayRange, setSelectedDayRange] = useState({
        from: '',
        to: ''
    });

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    const history = useHistory()




    // set filter for drop down 
    const handleFilterType = (e) => {
        setFilterType(e)
    }

    // update search 
    var editSearchVal = (f) => {
        setSearch(f.target.value)



    }

    // handle filter inputs 
    var addFilterInput = (val) => {
        if (val !== "") {
            var ar = filterInput[filterType]
            ar = [...ar, val]
            setfilterInput(oldState => ({ ...oldState, [String(filterType)]: ar }))
        }
        // setfilterInput(filterInput[filterType].push(searchTerm))
        console.log(filterInput)

    };




    var removeFilterInput = (key, index) => {

        var ar = filterInput[filterType]
        if (index > -1) {
            ar.splice(index, 1);
        }
        setfilterInput(oldState => ({ ...oldState, [String(key)]: ar }))
    };





    const items = [
        {
            id: 0,
            name: 'Cobol'
        },
        {
            id: 1,
            name: 'JavaScript'
        },
        {
            id: 2,
            name: 'Basic'
        },
        {
            id: 3,
            name: 'PHP'
        },
        {
            id: 4,
            name: 'Java'
        }
    ]



    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.

    }

    const handleOnHover = (result) => {
        // the item hovered

    }

    const handleOnSelect = (item) => {
        // the item selected

    }

    const handleOnFocus = () => {

    }

    const formatResult = (item) => {
        return item;
        // return 


    }


    const setdatefield = () => {
        if ((selectedDayRange.from === "" && selectedDayRange.from === "") || (selectedDayRange.to === null)) {

            return ("")
        }
        else {

            if (selectedDayRange.to === "") {
                return (String(selectedDayRange.from.day) + "/" + String(selectedDayRange.from.month) + "/" + String(selectedDayRange.from.year))

            } else {

                return (String(selectedDayRange.from.day) + "/" + String(selectedDayRange.from.month) + "/" + String(selectedDayRange.from.year) + " to " + String(selectedDayRange.to.day) + "/" + String(selectedDayRange.to.month) + "/" + String(selectedDayRange.to.year)
                )
            }
        }
    }




    return (
        <div className="card-div">
            <Card className="card border-warning text-dark bg-light mb-3 pull-center  homecard" >
                <h1 className="text-dark font-weight-bold" style={{ fontFamily: "'Libre Baskerville', serif", textDecoration: 'underline', textDecorationColor: "#f47c3c", textAlign: 'center' }}> Find  Your<span className="text-warning" style={{ fontFamily: "'Libre Baskerville', serif" }}> Adventure</span></h1>

                <div style={{ marginTop: "2vh" }} >

                    <div style={{ margin: "auto", alignItems: "center" }} >

                        <input className="" style={{ fontWeight: "bold", textAlign: "center", margin: "auto", display: "block" }} type="text" value={setdatefield()} readOnly onClick={() => {
                            document.documentElement.style.setProperty('--cal-size', '9px')


                        }} placeholder="Add A Date Range...." />
                    </div>





                    <div  >

                        <div ref={wrapperRef} className="calwrap" style={{ width: "0vw", margin: "0 35%", display: "block", textAlign: "center" }}>
                            <Calendar
                                value={selectedDayRange}
                                onChange={setSelectedDayRange}
                                calendarClassName="responsive-calendar-home" // added this
                                shouldHighlightWeekends
                                colorPrimary="#f47c3c"
                                colorPrimaryLight="#FBA070"



                            />

                        </div>
                    </div>

                </div>
                <Row style={{ marginTop: '1.5vh' }} >
                    <h5 style={{ textAlign: "center", fontFamily: "'Libre Baskerville', serif" }}><strong>Add Filters:</strong></h5>



                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown findtitle" style={{ width: "75%", margin: "auto", }}>
                        {/* style={{ width: '90%', margin: "auto" }} */}


                        <DropdownButton
                            as={ButtonGroup}
                            key={'Warning'}
                            id={`dropdown-variants-warning`}
                            variant={'warning'.toLowerCase()}
                            title={filterType}
                            onSelect={handleFilterType}
                        >
                            <Dropdown.Item eventKey="species">Species</Dropdown.Item>
                            <Dropdown.Item eventKey="sports">Sport</Dropdown.Item>
                            <Dropdown.Item eventKey="locations" active>
                                Location
                            </Dropdown.Item>
                        </DropdownButton>




                        <div style={{ width: "25vw" }} className="btn btn-warning">
                            <ReactSearchAutocomplete
                                items={items}
                                onSearch={handleOnSearch}
                                onHover={handleOnHover}
                                onSelect={(val) => { addFilterInput(val.name) }}
                                onFocus={() => { handleOnFocus("") }}
                                autoFocus
                                formatResult={formatResult}
                                styling={{ height: "30px", fontSize: "10px", zIndex: 1000, }}
                            />
                        </div>

                    </div>









                    {/* <input className="form-control me-sm-2" style={{}} type="text" value={searchTerm} onChange={editSearchVal} placeholder="Add A Filter...." wtx-context="DC6E452B-D84D-4763-87DD-57F445E7EC16" /> */}





                    <Row id="filters" style={{ marginTop: '2vh' }}>

                        {
                            Object.entries(filterInput).map((key) => {
                                console.log(key[1])
                                if (key[1].length != 0) {
                                    const ar = key[1]
                                    return (
                                        ar.map((name, index) => {
                                            return (

                                                <Col >
                                                    <button type="button" className="btn btn-outline-warning" onClick={() => removeFilterInput(key[0], index)}>{key[0]}: {name}&nbsp;&nbsp;&nbsp;<i className="fas fa-times "></i></button>

                                                </Col>
                                            )
                                        })
                                    )
                                } else {
                                    return
                                }

                            })
                        }


                    </Row>
                </Row>
                <Row style={{ marginTop: '4vh', marginBottom: '1.5vh' }}>


                    <button className="btn btn-warning" style={{ marginTop: '2vh', width: '20%', margin: 'auto', fontSize: '.6rem' }} type="submit" onClick={() => history.push('/search', filterInput)}>Search

                    </button>

                </Row>


            </Card >
        </div >
    )
}

export default HomeCard
