import React, { useState, useCallback } from 'react'
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { NavLink, useParams } from 'react-router-dom';
// import 'react-pro-sidebar/dist/css/styles.css';
import '../sidebar.css'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import '../bootstrap.min.css'
import Slider, { Range } from 'rc-slider';
// import 'rc-slider/assets/index.css';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Row, Col } from 'react-bootstrap'


// #f47c3c
function FilterSidebar({ setMargCoordinator }) {
    const [collapse, setCollapse] = useState(true)
    const today = new Date()
    const tomorrow = new Date()
    var [startDate, setStartDate] = useState(today)
    var [endDate, setEndDate] = useState(tomorrow.setDate(today.getDate() + 1))
    var [priceRange, setPriceRange] = useState([0, 100])
    var [numPeople, setNumPeople] = useState(null)
    var [duration, setDuration] = useState(null)
    var [filterInput, setfilterInput] = useState({ sports: [], species: [], locations: [], priceRange: [], startDate: [], endDate: [], duration: [], numPeople: [] });





    var addFilterInput = (obj, val) => {
        if (val !== "") {
            var ar = filterInput[obj]
            ar = [...ar, val]
            setfilterInput(oldState => ({ ...oldState, [String(obj)]: ar }))
        }
        // setfilterInput(filterInput[filterType].push(searchTerm))

    };
    const handlePriceChange = prices => {
        setPriceRange({ prices });
    };
    const handleNumPeople = num => {
        setNumPeople({ num })
    }
    const handleDuration = num => {
        setDuration({ num })
    }


    var singleValFilterInput = (obj, val) => {
        if (val !== "") {
            var ar = filterInput[obj]
            ar = [val]
            setfilterInput(oldState => ({ ...oldState, [String(obj)]: ar }))
        }
        // setfilterInput(filterInput[filterType].push(searchTerm))

    };



    var removeFilterInput = (obj, val) => {

        var ar = filterInput[obj]
        if (val > -1) {
            ar.splice(val, 1);
        }
        setfilterInput(oldState => ({ ...oldState, [String(obj)]: ar }))

    };

    const items = [
        {
            id: 0,
            name: 'Cobol'
        },
        {
            id: 1,
            name: 'JavaScript'
        },
        {
            id: 2,
            name: 'Basic'
        },
        {
            id: 3,
            name: 'PHP'
        },
        {
            id: 4,
            name: 'Java'
        }
    ]

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.

    }

    const handleOnHover = (result) => {
        // the item hovered

    }

    const handleOnSelect = (item) => {
        // the item selected

    }

    const handleOnFocus = () => {

    }

    const formatResult = (item) => {
        return item;
        // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
    }

    // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html

    const marks = {
        '-10': '-10',
        0: 0,
        26: '26',
        37: '37',
        50: '50',
        100: {
            style: {
                color: 'red',
            },
            label: 100
        },
    };
    const handleMarg = params => {
        if (collapse === true) {
            setMargCoordinator("18vw")
        } else {
            setMargCoordinator("0")

        }

    }



    return (
        <ProSidebar
            collapsed={collapse}
            collapsedWidth="35px"
            width="300px"

        >
            <Menu iconShape="round">
                {collapse ? (<btn onClick={() => {
                    setCollapse(false)
                    handleMarg()
                }} style={{ marginLeft: "1vh" }} > <i className="fas fa-bars" /></btn>) :
                    <btn onClick={() => {
                        setCollapse(true)
                        handleMarg()
                    }} style={{ marginLeft: "1vh" }}>Filters:</btn>}
                <MenuItem >
                </MenuItem>
                <MenuItem >
                    <div>
                        <h6> Locations:</h6>
                        <ReactSearchAutocomplete
                            items={items}

                            onHover={handleOnHover}
                            onSelect={(val) => { addFilterInput("locations", val) }}
                            onFocus={handleOnFocus}
                            autoFocus
                            formatResult={formatResult}
                            styling={{ height: "11px", fontSize: "10px", zIndex: 1000 }}
                        />
                    </div>
                    <div className="card bg-light" style={{ maxWidth: "20rem", marginTop: "15px", paddingBottom: "9vh" }}>
                        <Row id="filters" >
                            {
                                Object.entries(filterInput).map((key) => {
                                    if (key[1].length != 0 && key[0] === "locations") {

                                        const ar = key[1]
                                        return (
                                            ar.map((name, index) => {
                                                return (

                                                    <Col >
                                                        <button type="button" className="badge bg-primary" onClick={() => removeFilterInput(key[0], index)}>{name.name}&nbsp;&nbsp;&nbsp;<i className="fas fa-times "></i></button>

                                                    </Col>
                                                )
                                            })
                                        )
                                    } else {
                                        return
                                    }

                                })
                            }
                        </Row>

                    </div>







                </MenuItem>
                <MenuItem></MenuItem>
                <MenuItem >

                    <div>
                        <h6> Sports:</h6>
                        <ReactSearchAutocomplete
                            items={items}
                            onSearch={handleOnSearch}
                            onHover={handleOnHover}
                            onSelect={(val) => { addFilterInput("sports", val) }}
                            onFocus={() => { handleOnFocus("") }}
                            autoFocus
                            formatResult={formatResult}
                            styling={{ height: "11px", fontSize: "10px", zIndex: 1000 }}
                        />
                    </div>
                    <div className="card border-secondary bg-light" style={{ maxWidth: "20rem", marginTop: "15px", paddingBottom: "9vh" }}>
                        <Row id="filters" >

                            {
                                Object.entries(filterInput).map((key) => {
                                    if (key[1].length != 0 && key[0] === "sports") {
                                        const ar = key[1]
                                        return (
                                            ar.map((name, index) => {
                                                return (

                                                    <Col >
                                                        <button type="button" className="badge  bg-primary" onClick={() => removeFilterInput(key[0], index)}>{name.name}&nbsp;&nbsp;&nbsp;<i className="fas fa-times "></i></button>

                                                    </Col>
                                                )
                                            })
                                        )
                                    } else {
                                        return
                                    }

                                })
                            }


                        </Row>
                    </div>


                </MenuItem>
                <MenuItem >
                    <div>
                        <h6> Species:</h6>
                        <ReactSearchAutocomplete
                            items={items}
                            onSearch={handleOnSearch}
                            onHover={handleOnHover}
                            onSelect={(val) => { addFilterInput("species", val) }}
                            onFocus={handleOnFocus}
                            autoFocus
                            formatResult={formatResult}
                            styling={{ height: "11px", fontSize: "10px", zIndex: 1000 }}
                        />
                    </div>
                    <div className="card bg-light" style={{ maxWidth: "20rem", marginTop: "15px", paddingBottom: "9vh" }}>
                        <Row id="filters" >
                            {
                                Object.entries(filterInput).map((key) => {
                                    if (key[1].length != 0 && key[0] === "species") {

                                        const ar = key[1]
                                        return (
                                            ar.map((name, index) => {
                                                return (

                                                    <Col >
                                                        <button type="button" className="badge   bg-primary" onClick={() => removeFilterInput(key[0], index)}>{name.name}&nbsp;&nbsp;&nbsp;<i className="fas fa-times "></i></button>

                                                    </Col>
                                                )
                                            })
                                        )
                                    } else {
                                        return
                                    }

                                })
                            }
                        </Row>

                    </div>


                </MenuItem>
                <MenuItem>
                    <h6>Price:</h6>
                    <div style={{ padding: "2vh 5vw 10vh 5vw ", width: "" }}>
                        <Range
                            min={0}
                            max={120}
                            onChange={(e) => {
                                handlePriceChange(e)
                                singleValFilterInput("priceRange", e)

                            }}
                        />
                    </div>
                </MenuItem>

                <MenuItem >
                    <div> StartDate/Duration

                        <Row style={{ margin: 'auto' }}>
                            <Col>
                                <h6>Start Date:</h6>
                                <div>

                                    <DatePicker
                                        selected={startDate}
                                        onChange={(val) => {
                                            const toisos = val.toISOString()
                                            setStartDate(val)
                                            singleValFilterInput("startDate", toisos)
                                        }}
                                        dateFormat='MM-dd-yyyy'
                                        minDate={today}
                                        wrapperClassName="datePicker"
                                    />
                                </div>
                            </Col>
                            <Col>
                                <h6>End Date:</h6>
                                <div>

                                    <DatePicker
                                        selected={endDate}
                                        onChange={(val) => {
                                            const toisoe = val.toISOString()

                                            setEndDate(val)
                                            singleValFilterInput("endDate", toisoe)
                                        }
                                        }
                                        dateFormat='MM-dd-yyy'
                                        minDate={today}

                                    />
                                </div>
                            </Col>

                        </Row>




                    </div>

                </MenuItem>
                <MenuItem >
                    <h6>Duration:</h6>
                    <div style={{ padding: "2vh 5vw 10vh 5vw " }}>
                        <Slider
                            min={0}
                            max={120}
                            onChange={(e) => {
                                handleDuration(e)
                                singleValFilterInput("duration", e)

                            }}

                            railStyle={{
                                height: 2
                            }}
                            handleStyle={{

                            }}

                        />
                    </div>
                </MenuItem>
                <MenuItem >
                    <h6>Number of People:</h6>
                    <div style={{ padding: "2vh 5vw 10vh 5vw " }}>
                        <Slider
                            min={0}
                            max={120}
                            onChange={(e) => {
                                handleNumPeople(e)
                                singleValFilterInput("numPeople", e)

                            }}

                            railStyle={{
                                height: 2
                            }}
                            handleStyle={{

                            }}

                        />
                    </div>
                </MenuItem>

            </Menu>
        </ProSidebar >
    )
}

export default FilterSidebar
